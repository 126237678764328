import React from 'react';
const PhoneIcon = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="12.859" height="18.959"
            viewBox="0 0 12.859 18.959">
            <path id="Path_20207" data-name="Path 20207"
                d="M218.713,410.759A2.642,2.642,0,0,1,216,408.2V394.558A2.642,2.642,0,0,1,218.713,392h7.234a2.642,2.642,0,0,1,2.713,2.558V408.2a2.642,2.642,0,0,1-2.713,2.558Zm-1.808-16.2V408.2a1.759,1.759,0,0,0,1.808,1.705h7.234a1.759,1.759,0,0,0,1.808-1.705V394.558a1.759,1.759,0,0,0-1.808-1.705h-7.234A1.759,1.759,0,0,0,216.9,394.558Zm4.521,13.643a.906.906,0,1,1,.9.853A.88.88,0,0,1,221.425,408.2Zm-.452-13.643a.427.427,0,1,1,0-.853h2.713a.427.427,0,1,1,0,.853Z"
                transform="translate(-215.9 -391.9)" stroke="#000" strokeWidth="0.2" />
        </svg>
     );
}
 
export default PhoneIcon;