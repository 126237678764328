import React from 'react';
import {Link} from 'react-router-dom'
const HomeAbout = (props) => {
    return ( 
        <section className="hm-about-sec">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-7 mb-3">
                        <div className="hm-about__inner">
                            <h2>{props.hometitle}</h2>
                            <div className="hm-about__desc">
                                <p  dangerouslySetInnerHTML={{__html:props.content}}  className="home-about-content"> 
                                </p>
                            </div>
                        </div>
                        <Link to="/about" className="btn btn-qk-contact d-none d-lg-flex my-auto"  style={{width:190}}>
                            <span className="d-none d-md-block">More About Us</span>
                            <img src={require("../asstes/images/icons/icon-plane-arrow.svg").default} className="img-fluid btn-icon-right" alt=""/>
                        </Link>
                    </div>
                    <div className="col-md-5 mb-3">
                        <div className="position-relative">
                            <div className="hm-about__image">
                                <img src={require("../asstes/images/index/hm-about.png")} className="img-fluid w-100" alt="images"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default HomeAbout ;