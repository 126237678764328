import React, { useEffect } from 'react';
import { useState } from 'react';
import {Link,useLocation} from 'react-router-dom';
const MainHeader = ({children}) => {
    const [state,setState] = useState({
        active : 'home',
        show : false
    });
    const location = useLocation();
    const {
        active,
        show
    }= state;
    useEffect(()=>{
        const {pathname}  = location;
        if(pathname.includes('viewProduct')){
            setState(prev=>({
                ...prev,
                active : '/product',
                show:false
            }));
        }else{
            setState(prev=>({
                ...prev,
                active : pathname,
                show:false
            }));
        }
    },[active,location]);
    return ( 
        <div>
                <nav className={`mobile-navbar navbar navbar-expand-lg w-100 ${active === '/home' && 'position-absolute nav-top'}`}>
                    <div className="container navbar-container">
                        <Link className="navbar-brand" to="/">
                            <img src={require("../asstes/images/logo/maieco.svg").default} className="img-fluid" alt="Maieco"/>
                        </Link>


                        <a href="tel:+919072849777" className="btn cta-btn-call ms-auto align-self-center me-3 d-lg-none">
                            <span className="d-none d-md-block me-2">Call For Help</span>
                            <img src={require("../asstes/images/icons/icon-phone.svg").default} className="img-fluid" alt=""/>
                        </a>

                        <button className="navbar-toggler align-self-center" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            onClick={()=>{
                                setState(prev=>({
                                    ...prev,
                                    show : !show
                                }))
                            }}
                            aria-label="Toggle navigation">
                            <img
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAKElEQVRIiWNgGAXDHjAisf/TwmwmKhs6CgYhGE1Fo4ByMJqKRsEQAADWCQMKYvEFtQAAAABJRU5ErkJggg==" alt="" />
                        </button>


                        <div className={`collapse navbar-collapse flex-grow-1 ${show&&'show'}`} id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                <li className={`nav-item ${active==='/home'&&'active'}`}>
                                    <Link className="nav-link" aria-current="page" to="/" >Home</Link>
                                    {/* <a className="nav-link" aria-current="page" href="index.php">Home</a> */}
                                </li>
                                <li className={`nav-item ${active==='/about'&&'active'}`}>
                                    <Link className="nav-link" aria-current="page" to="/about">About Us</Link>
                                    {/* <a className="nav-link" href="about.php">About Us</a> */}
                                </li>
                                <li className={`nav-item ${active==='/product'&&'active'}`}>
                                    <Link className="nav-link" aria-current="page" to="/product" >Products</Link>
                                    {/* <a className="nav-link" href="products.php">Products</a> */}
                                </li>
                                <li className={`nav-item ${active==='/distributors'&&'active'}`}>
                                    <Link className="nav-link" aria-current="page" to="/distributors" >Distributors</Link>
                                    {/* <a className="nav-link" href="distributors.php">Distributors</a> */}
                                </li>
                                <li className={`nav-item ${active==='/Contact'&&'active'}`}>
                                    <Link className="nav-link" aria-current="page" to="/Contact" >Contact Us</Link>
                                    {/* <a className="nav-link" href="contact.php">Contact Us</a> */}
                                </li>
                            </ul>
                        </div>

                        <a href="tel:+919072849777" className="btn btn-qk-contact d-none d-lg-flex my-auto">
                            <span className="d-none d-md-block">Contact Us</span>
                            <img src={require("../asstes/images/icons/icon-phone.svg").default} className="img-fluid btn-icon-right" alt=""/>
                        </a>

                    </div>
            </nav>
            {children}
        </div>
     );
}
 
export default MainHeader;