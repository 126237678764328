import React from 'react';
const Loader = () => {
    return (
        <div id="preloader">
            <div id="status">
                <img src={require('../../asstes/images/icons/loader.svg').default} className="img-fluid" alt="loader"/>
            </div>
        </div>
      );
}
 
export default Loader;