import React,{useState,useEffect} from 'react';
import Banner from '../components/common/Banner';
import bannerImage from '../asstes/images/background/conact-bg.png';
import BreadCrumb from '../components/common/BreadCumb';
import HomeService from '../service/home';
import {useParams,useHistory,Link} from 'react-router-dom';
import Loader from '../components/common/Loader';
import {Footer} from '../components';
import {
    Tab,
    Tabs
} from 'react-bootstrap'
const ViewProduct = () => {
    const breadcrums = [
        {
            name : 'Home',
            to : "/home"
        },
        {
            name : 'Product',
            to : "/product"
        },
        {
            name : 'Product Details'
        }
    ];
    let { id } = useParams();
    const history = useHistory();
    const [state,setState] = useState({
        allProduct  : [],
        productDetails : {},
        loading : true
    })
    const [key, setKey] = useState(0);
    useEffect(()=>{
        if(!id){
            history.push('/home');
        }else{
            setKey(0)
            const service = new HomeService();
            service.getProducts(id).then(res=>{
                if(res.status && res.data.length>0){
                    const temp = 'test';
                    temp.substr(0, 1)
                    console.log(temp);
                    setState(prev=>({
                        ...prev,
                        productDetails : res.data[0],
                        loading : false
                    }))
                }else{
                    history.push('/home')
                }
            })
            service.getProducts().then(res=>{
                if(res.status){
                    setState(prev=>({
                        ...prev,
                        allProduct : res.data
                    }))
                }
            })
        }
        // console.log(state);
    },[id,history])
    if(state.loading){
        return <Loader/>
    }
    return ( 
        <div>
            <Banner
                title={state?.productDetails?.title}
                image={state?.productDetails?.cover??bannerImage}
            />
            <BreadCrumb 
                data={breadcrums}
                title="Contact Us"
            />
            <section className="product-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 mb-30">
                            <div className="product-details__sidebar">
                                <ul>
                                    {
                                        state.allProduct.map((item,index)=>
                                            <li className={`${item.prod_id === state.productDetails.prod_id && 'active'}`} key={index}>
                                                <Link to={`/viewProduct/${item.prod_id}`}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        )
                                    }

                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-8 mb-30">
                            <div className="product-details__main">
                                <div className="main-content">
                                    <div className="main-content__image">
                                        <img src={state.productDetails.image} className="img-fluid w-100"
                                            alt=""/>
                                    </div>
                                    <div className="main-content__title">
                                        {state.productDetails.title}
                                    </div>
                                    <div className="main-content__desc">
                                        <p> {state.productDetails.discription}</p>
                                    </div>
                                    {
                                        state.productDetails.features &&state.productDetails.features.length>0&&
                                        <ul className="main-content__list">
                                            {
                                                
                                                state.productDetails.features.map((item,index)=>
                                                    <li className="main-content__list-item" key={index}>
                                                        {item}
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    }
                                    {/* {
                                        state.productDetails.property&&state.productDetails.property.length>0&&
                                        <div className="badges-list">
                                            <div className="row">
                                                {
                                                    
                                                    state.productDetails.property.map((item,index)=>
                                                        <div className="col-12 col-sm-6 mb-30" key={index}>
                                                            <div className="badges-list__item">{item.name}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    } */}

                                </div>
                            </div>
                        </div>
                        <div className="pro-desc-tabs">

                            {
                                state.productDetails.property&&state.productDetails.property.length>0&&
                                <Tabs
                                    id="controlled-tab-example"
                                    activeKey={key}
                                    onSelect={(k) => setKey(k)}
                                    className="mb-3 tab-scroll"
                                    >
                                    {
                                        state.productDetails.property&&state.productDetails.property.length>0&&
                                        state.productDetails.property.map((item,index)=>
                                        <Tab eventKey={index} title={item.name} key={index}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-8 mx-auto">
                                                        <p className="pro-desc-tab-head text-center mt-5">
                                                            {item.title} 
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    {
                                                        item.description&&item.description&&
                                                        item.description.map((discItem)=>(
                                                            <div class="col-lg-4">
                                                                <span dangerouslySetInnerHTML={{__html:discItem}}></span>   
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </Tab>
                                        )
                                    }
                                </Tabs>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
     );
}
 
export default ViewProduct;