import React from 'react';
const AddressIcon = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18.732"
            viewBox="0 0 15 18.732">
            <g id="_064-pin-1" data-name="064-pin-1" transform="translate(-51.003)">
                <g id="Group_10841" data-name="Group 10841"
                    transform="translate(53.676 2.772)">
                    <g id="Group_10840" data-name="Group 10840">
                        <path id="Path_19352" data-name="Path 19352"
                            d="M135.827,80a4.827,4.827,0,1,0,1.324,9.47.772.772,0,1,0-.423-1.486,3.278,3.278,0,1,1,2.244-2.213.772.772,0,0,0,1.48.444A4.83,4.83,0,0,0,135.827,80Z"
                            transform="translate(-131 -80)" />
                    </g>
                </g>
                <g id="Group_10843" data-name="Group 10843" transform="translate(51.003)">
                    <g id="Group_10842" data-name="Group 10842">
                        <path id="Path_19353" data-name="Path 19353"
                            d="M63.8,2.194A7.5,7.5,0,0,0,51,7.493a7.837,7.837,0,0,0,1.238,4.182,22.275,22.275,0,0,0,2.646,3.284,24.976,24.976,0,0,1,2.778,3.432.732.732,0,0,0,.619.341h.439a.732.732,0,0,0,.619-.341,24.976,24.976,0,0,1,2.778-3.432,22.272,22.272,0,0,0,2.646-3.284A7.838,7.838,0,0,0,66,7.491,7.451,7.451,0,0,0,63.8,2.194ZM61.038,13.972A30.158,30.158,0,0,0,58.5,17.01a30.156,30.156,0,0,0-2.535-3.038c-1.878-2.056-3.5-3.832-3.5-6.479a6.037,6.037,0,0,1,12.073,0C64.537,10.14,62.915,11.916,61.038,13.972Z"
                            transform="translate(-51.003)" />
                    </g>
                </g>
            </g>
        </svg>
     );
}
 
export default AddressIcon;