import React from "react";
import Banner from "../components/common/Banner";
import bannerImage from "../asstes/images/about/about.png";
import BreadCrumb from "../components/common/BreadCumb";
import HomeService from "../service/home";
import { useState } from "react";
import { useEffect } from "react";
// import aboutBg from '../asstes/images/about/about-bg.png';
// import CountUp from 'react-countup';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Products, Enquiry, Footer } from "../components";
import Loader from "../components/common/Loader";

const About = () => {
  const [state, setState] = useState({
    products: [],
    about: {},
    profile: {},
    distribuors: [],
    loading: true,
  });
  useEffect(() => {
    const service = new HomeService();
    service.getAboutUs().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          about: res.data,
          loading: false,
        }));
      }
    });
    service.getProducts().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          products: res.data,
          loading: false,
        }));
      }
    });
    service.getProfile().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          profile: res.data,
          loading: false,
        }));
      }
    });
    service.distribution().then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          distribuors: res.data,
          loading: false,
        }));
      }
    });
  }, []);
  const breadcrums = [
    {
      name: "Home",
      to: "/home",
    },
    {
      name: "About Us",
    },
  ];
  if (state.loading) {
    return <Loader />;
  }
  return (
    <div>
      <Banner title="About Us" image={bannerImage} />
      <BreadCrumb data={breadcrums} title="About Us" />
      <section className="about-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-3">
              <div className="position-relative about-main__image  h-100">
                <div className="about-main__image-title">
                  <h2>46+ Years</h2>
                  {/* <h4></h4> */}
                </div>
                <div className="about-main_image-large">
                  <img
                    src={require("../asstes/images/about/abt-main.png")}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
                <div className="about-main_image-small">
                  <img
                    src={require("../asstes/images/about/abt-sm.png")}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="about-main__content mt-lg-4">
                <h4 className="title-main">{state.about.title}</h4>
                <div className="about-main__content-desc">
                  <p
                    dangerouslySetInnerHTML={{ __html: state.about.content }}
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="quote-block">
        <div className="container">
          <div
            className="quote-block__container"
            style={{ background: "#F2F2F2" }}
          >
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="d-flex flex-column justify-content-md-center h-100">
                  <div className="quote-block__content">
                    <div className="quote-block__title text-uppercase">
                      MATHEW VARGHESE
                    </div>
                    <h4 className="quote-block__sub-title">Founder, Maieco</h4>
                    <div className="quote-block__text-desc">
                      <p>
                        Mr. Mathew Varghese,a graduate in mechanical
                        engineering, specialised in Fibre Reinforced Plastics
                        from IIT, Madras. He went on to start his own
                        entrepreneurial venture in the year 1976. He founded
                        Maieco, and started with the manufacturing of Fiber
                        reinforced products in a humble facility.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="quote-block__image">
                  <img
                    src={
                      require("../asstes/images/about/about-founder.png")
                    }
                    className="img-fluid w-100 h-100"
                    alt="founder"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Distributors List --> */}
      <section className="distibutors-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="sec-heading text-center">
                <h2 className="main-header">Our Distributors</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="position-relative distibutors-section__inner">
            {/* <div className="slider-distributors owl-carousel owl-theme"> */}
            <OwlCarousel
              className="slider-distributors owl-main  owl-theme"
              items={1}
              loop
              responsiveClass={true}
              center={true}
              margin={5}
              autoplay={false}
              smartSpeed={1200}
              autoplaySpeed={2000}
              autoplayTimeout={2000}
              autoplayHoverPause={false}
              // navClass="owl-prev"
              responsive={{
                0: {
                  items: 1,
                  dots: true,
                },
                600: {
                  items: 2,
                },
                768: {
                  dots: false,
                  items: 2,
                },
                991: {
                  items: 3,
                },
                1200: {
                  items: 3,
                  dots: true,
                  nav: true,
                },
              }}
            >
              {state.distribuors.map((item, index) => (
                <div className="distibutors-list" key={index}>
                  <div className="distibutors-list__title-sm">
                    {item.district}
                  </div>
                  <h3 className="distibutors-list__title-lg">
                    {item.companyName}, {item.district}
                  </h3>
                  <div className="distibutors-list__content">
                    <ul>
                      <li>
                        <strong> Address:-</strong>
                        {item.address}, {item.landMark},{item.city},{" "}
                        {item.district} - {item.pincode}
                      </li>
                      <li>
                        <strong> Phone :- </strong>
                        {item.phone1}, {item.phone2}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}

              {/* <!-- Slide END --> */}
            </OwlCarousel>
            <button className="btn btn-slider-l js-button-left owl-prev">
              <img
                src={
                  require("../asstes/images/icons/arrow-long-left.svg").default
                }
                className="img-fluid"
                alt="arrow left"
              />
            </button>
            <button className="btn btn-slider-r js-button-right">
              <img
                src={
                  require("../asstes/images/icons/arrow-right-long.svg").default
                }
                className="img-fluid"
                alt="arrow right"
              />
            </button>

            {/* </div> */}
          </div>
        </div>
      </section>
      <Products products={state.products} />
      <Enquiry {...state.profile} />
      <Footer />
    </div>
  );
};

export default About;
